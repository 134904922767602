.bgContainer {
    background-image: url("../Media/bg-2.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .container {
    display: flex;
    flex-direction: column; /* Adjusted from the commented-out section */
    justify-content: center;
    align-items: center;
    background-color: rgba(135, 38, 83, 0.5);
    padding: 25px; /* Adjust padding as needed */
    width: 100%; /* Adjust width to fit the parent container */
    max-width: 800px; /* Adjust the maximum width as needed */
    margin: 0 auto; /* Center horizontally */
    min-width: 300px;
    border-radius: 4px;
  }
  
  .infoContainer {
    padding: 49px 45px; /* Adjust padding as needed */
  }
  
  .title {
    font-family: "Black Ops One", "Vina Sans";
    font-size: 60px;
  }
  
  p {
    color: whitesmoke;
    text-align: center;
  }
  
  .inputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .inputContainer > button {
    /* outline: 1px solid dodgerblue; */
    padding: 10px;
    border-radius: 4px;
    opacity: 1;
    transition: opacity 0.3s ease;
    width: 100%;
    max-width: 400px;
    min-width: 300px;
    font-family: 'Courier New', Courier, monospace;
    letter-spacing: 4px;
    font-size: 20px;
    font-weight: 700;
    color: gray;
    background-color: white;
  }
  .inputContainer > button:hover {
    color: white;
    background-color: black;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  @media screen and (max-width: 768px) {
      .infoContainer{
          padding: 10px;
      }
      .title {
          font-size: 40px;
      }
      .container {
          height: 500px;
      }
  }